
import React, { useState } from 'react';
import { useMsal } from '@azure/msal-react'; // Correct package


const FileUploader = () => {
    const { accounts } = useMsal();
    const isDevelopment = process.env.NODE_ENV === 'development';

    // Apply the same logic to determine userName as in AppContent
    const userName = isDevelopment ? "kilroy@uark.edu" : accounts?.[0]?.username;

    // Updated state to handle multiple files
    const [files, setFiles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [savedToFileBaby, setSavedToFileBaby] = useState(false);

    // Handle multiple file changes (selection or drag-and-drop)
    const handleFileChange = (event) => {
        const selectedFiles = Array.from(event.target.files);
        setFiles([...files, ...selectedFiles]);  // Append selected files
        console.log("Files selected:", selectedFiles);
    };

    // Handle drag-and-drop for multiple files
    const handleDrop = (event) => {
        event.preventDefault();
        const droppedFiles = Array.from(event.dataTransfer.files);
        setFiles([...files, ...droppedFiles]);  // Append dropped files
        console.log("Files dropped:", droppedFiles);
    };

    // Handle file uploading (for multiple files)
    const handleSaveToFileBaby = async () => {
        if (files.length === 0) {
            setError('No files selected.');
            return;
        }

        setIsLoading(true);
        setError('');

        try {
            for (const file of files) {
                const containerUrl = 'https://drive.nyx.baby/nyxnocode';
                const sasToken = process.env.REACT_APP_SAS_TOKEN;
                const filePath = `${containerUrl}/${userName}/${file.name}?${sasToken}`;

                const response = await fetch(filePath, {
                    method: 'PUT',
                    headers: {
                        'x-ms-blob-type': 'BlockBlob',
                        'Content-Type': file.type,
                    },
                    body: file,
                });

                if (!response.ok) {
                    throw new Error(`Failed to save file ${file.name} with status: ${response.status}`);
                }
            }
            setSavedToFileBaby(true);
            setFiles([]);  // Clear files after upload
            document.getElementById('fileInput').value = '';  // Clear file selection box
            setTimeout(() => setSavedToFileBaby(false), 3000);
        } catch (error) {
            console.error('Error saving files:', error);
            setError(`Error saving files: ${error.message}`);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        await handleSaveToFileBaby();
    };

    return (
        <div className="file-uploader-box" onDragOver={(e) => e.preventDefault()} onDrop={handleDrop}>
            <form onSubmit={handleSubmit}>
                <div>
                    <input
                        tabIndex={"0"}
                        id="fileInput"
                        type="file"
                        onChange={handleFileChange}
                        disabled={isLoading}
                        multiple  // Allow multiple file selection
                        title={"Choose multiple files to upload or drag and drop files here"}
                    />
                    <button tabIndex={"0"} type="submit" title="Upload Files to Gallery" disabled={isLoading || files.length === 0}>
                        {isLoading ? 'Uploading...' : 'Upload Files'}
                    </button>
                </div>
            </form>

            {files.length > 0 && <p>{files.length} file(s) ready to upload</p>}  {/* Display file count */}
            {isLoading && <p>Uploading...</p>}
            {error && <p className="error">{{error}}</p>}
            {savedToFileBaby && <p>Files saved to Gallery successfully!</p>}
        </div>
    );
}

export default FileUploader;
