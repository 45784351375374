import React, { useState, useEffect, useCallback } from 'react';
import { RefreshCw } from 'lucide-react';

const FolderSelector = ({ tenant = '', containerUrl, sasToken, onFolderSelect, currentPath, refreshTrigger, selectedFiles = [] }) => {
    const initialPath = currentPath || tenant;
    const [folders, setFolders] = useState([]);
    const [error, setError] = useState('');
    const [folderFilter, setFolderFilter] = useState('');
    const [currentFolderPath, setCurrentFolderPath] = useState(initialPath);
    const [selectKey, setSelectKey] = useState(0);
    const [isRefreshing, setIsRefreshing] = useState(false);

    const fetchFolders = useCallback(async () => {
        if (!containerUrl) return;

        setError('');
        setFolders([]);
        setIsRefreshing(true);

        try {
            const prefix = currentFolderPath ? `${encodeURIComponent(currentFolderPath)}/` : '';
            const response = await fetch(`${containerUrl}?restype=container&comp=list&prefix=${prefix}&delimiter=/`);

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.text();
            const parser = new DOMParser();
            const xml = parser.parseFromString(data, "application/xml");
            const folderNodes = Array.from(xml.querySelectorAll('BlobPrefix'));

            const foldersList = folderNodes.map(folder => {
                const folderName = folder.querySelector('Name').textContent;
                const name = currentFolderPath
                    ? folderName.replace(`${currentFolderPath}/`, '').replace('/', '')
                    : folderName.replace('/', '');
                return {
                    name,
                    fullPath: folderName
                };
            });

            setFolders(foldersList);
            setSelectKey(prev => prev + 1);
        } catch (e) {
            setError(`Failed to load folders: ${e.message}`);
            console.error('Folder fetch error:', e);
        } finally {
            setIsRefreshing(false);
        }
    }, [currentFolderPath, containerUrl]);

    useEffect(() => {
        fetchFolders();
    }, [fetchFolders, refreshTrigger]);

    useEffect(() => {
        setSelectKey(prev => prev + 1);
    }, [folderFilter]);

    const handleBackClick = () => {
        if (currentFolderPath && currentFolderPath.includes('/')) {
            const parentPath = currentFolderPath.split('/').slice(0, -1).join('/');
            setCurrentFolderPath(parentPath);
        } else {
            setCurrentFolderPath(tenant);
        }
    };

    const handleManualRefresh = async () => {
        await fetchFolders();
    };

    const handleFolderSelection = (value) => {
        if (selectedFiles.length === 0) {
            alert('Please select files to move first.');
            return;
        }

        const confirmMessage = "Moving these files may cause broken links if:\n\n" +
            "- You have built pages using these files\n" +
            "- You have shared direct links to these files\n" +
            "- Other users have referenced these files in their content\n\n" +
            "Are you sure you want to move the selected files?";

        if (window.confirm(confirmMessage)) {
            if (value === 'root') {
                const emailOnly = tenant.split('/')[0];
                console.log('Moving to root with path:', emailOnly);
                onFolderSelect(emailOnly);
                setCurrentFolderPath(emailOnly);
            } else {
                const newPath = currentFolderPath
                    ? `${currentFolderPath}/${value}`
                    : value;
                onFolderSelect(newPath);
                setCurrentFolderPath(newPath);
            }
        }
    };

    const filteredFolders = folders.filter(folder =>
        folder.name.toLowerCase().includes((folderFilter || '').toLowerCase())
    );

    return (
        <div className="folder-selector">
            <div className="flex items-center justify-between mb-4">
                <p className={"warning"}>⚠️Move files to folder: Select files, then choose a destination folder below (use with caution, this can break links on web pages and shared images):</p>
                <button
                    onClick={handleManualRefresh}
                    disabled={isRefreshing}
                    className="flex items-center gap-2 px-3 py-1 text-sm bg-blue-500 text-white rounded hover:bg-blue-600 disabled:bg-blue-300"
                >
                    <RefreshCw className={`w-4 h-4 ${isRefreshing ? 'animate-spin' : ''}`} />
                    Refresh
                </button>
            </div>

            <div className="folder-navigation">
                <button
                    onClick={handleBackClick}
                    className="back-button"
                >
                    ← Go Back a Level
                </button>
                <span className="current-path">
                    {currentFolderPath || '/'}
                </span>
            </div>

            <input
                type="text"
                value={folderFilter}
                onChange={(e) => setFolderFilter(e.target.value)}
                placeholder="Search folders..."
                className="folder-search"
            />

            <div className="folder-list-container">
                <select
                    key={selectKey}
                    className="folder-select"
                    onChange={(e) => handleFolderSelection(e.target.value)}
                    size={5}
                    // Remove the value="" attribute so it can be selected
                >
                    <option value="root">Move to Root Gallery</option>
                    {filteredFolders.map((folder, index) => (
                        <option key={index} value={folder.name}>
                            {folder.name}
                        </option>
                    ))}
                </select>

            </div>

            {error && <p className="error-message">{error}</p>}
        </div>
    );
};

export default FolderSelector;