// SavedItem.js
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './SavedItem.css'; // Ensure you have styling for flash-button in this CSS file

const SavedItem = ({ type, url }) => {
    const [flashButton, setFlashButton] = useState(false);

    // Trigger flashing effect when a new item is saved
    useEffect(() => {
        setFlashButton(true);
        const flashTimeout = setTimeout(() => setFlashButton(false), 3000); // Flash for 3 seconds
        return () => clearTimeout(flashTimeout);
    }, [url]);

    const handleCopyLink = () => {
        navigator.clipboard.writeText(url)
            .then(() => alert(`${type} link copied to clipboard!`))
            .catch(() => alert('Failed to copy the link.'));
    };

    const handlePreview = () => {
        window.open(url, '_blank');
    };

    return (
        <div className={`saved-item ${type.toLowerCase()}`}>
            <p className={"success-message"}>{type} saved successfully!</p>
            <input type="text" value={url} readOnly />
            <div className="button-group">
                <button onClick={handleCopyLink}>🔗 Copy {type} Link</button>
                <button
                    onClick={handlePreview}
                    className={`view-button ${flashButton ? "flash-button" : ""}`}
                >
                    View {type}
                </button>
            </div>
        </div>
    );
};

SavedItem.propTypes = {
    type: PropTypes.oneOf(['Web Page', 'Image']).isRequired,
    url: PropTypes.string.isRequired,
};

export default SavedItem;
