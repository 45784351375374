import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import './TenantFileGallery.css';
import foldericon from './folder.png';
import FolderSelector from './FolderSelector';
import FileUploader from './FileUploader';
import Magnify from './img/space_themed_magnifying_glass_icon_cartoon__red__black__silver-20241031-023151.png'

function TenantFileGallery({ userName, filterCriteria }) {
  const [tenant, setTenant] = useState(userName || '');
  const [files, setFiles] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [error, setError] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [nameFilter, setNameFilter] = useState('');
  const [newFolderName, setNewFolderName] = useState('');
  const [folders, setFolders] = useState([]);
  const [showModal, setShowModal] = useState(false); // State to control the modal visibility
  const [showWarningModal, setShowWarningModal] = useState(false); // State to control warning modal visibility

  const [isHelpChatOpen, setIsHelpChatOpen] = useState(false);

  const toggleHelpChat = () => {
    setIsHelpChatOpen(!isHelpChatOpen);
  };
  const sasToken = process.env.REACT_APP_SAS_TOKEN;
  const containerUrl = `https://drive.nyx.baby/nyxnocode`;
  const navigate = useNavigate();
  const selectAllVisibleFiles = () => {
    if (currentFiles.every(file => selectedFiles.includes(file))) {
      // Deselect all visible files if they are all already selected
      setSelectedFiles(prevSelected => prevSelected.filter(file => !currentFiles.includes(file)));
    } else {
      // Select all visible files
      setSelectedFiles(prevSelected => [
        ...prevSelected,
        ...currentFiles.filter(file => !prevSelected.includes(file)) // Only add files that aren't already selected
      ]);
    }
  };


  const fetchData = useCallback(async () => {
    setError('');
    setFiles([]);
    setFolders([]);

    try {
      const response = await fetch(`${containerUrl}?restype=container&comp=list&prefix=${encodeURIComponent(tenant)}/&delimiter=/`);
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      const data = await response.text();

      const parser = new DOMParser();
      const xml = parser.parseFromString(data, 'application/xml');

      // Process folders (BlobPrefix)
      const folderNodes = Array.from(xml.querySelectorAll('BlobPrefix'));
      const foldersData = folderNodes.map((folder) => {
        const folderPath = folder.querySelector('Name').textContent;
        const folderName = folderPath.replace(`${tenant}/`, '').slice(0, -1);
        const folderUrl = `${containerUrl}/${encodeURIComponent(folderPath)}`;
        return { name: folderName, isFolder: true, url: folderUrl };
      }).filter(folder => folder.name); // Filter out empty folder names

      // Process files (Blob)
      const blobs = Array.from(xml.querySelectorAll('Blob'));
      const filesData = blobs.map((blob) => {
        const fullPath = blob.querySelector('Name').textContent;
        const fileName = fullPath.split('/').pop();
        const url = `${containerUrl}/${encodeURIComponent(fullPath)}`;
        const fileExtension = fileName.split('.').pop();
        return { name: fileName, url, type: fileExtension, isFolder: false };
      }).filter(file => file.name); // Filter out empty file names

      setFolders(foldersData);
      setFiles(filesData);
    } catch (e) {
      setError(`Failed to load resources. ${e.message}`);
    }
  }, [tenant, containerUrl]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleTenantChange = (event) => {
    setTenant(event.target.value);
  };

  const getFileThumbnail = (file) => {
    if (file.type.match(/(pdf)$/i)) return './pdf_placeholder.png';
    if (file.type.match(/(html)$/i)) return './html_placeholder.png';
    if (file.type.match(/(tif|tiff|image\/tiff|image\/x-tiff)$/i)) return './tiff_placeholder.png';
    if (file.type.match(/(mp3)$/i)) return './mp3_placeholder.png';
    if (file.type.match(/(mp4)$/i)) return './mp4_placeholder.png';
    if (file.type.match(/(mov)$/i)) return './mov_placeholder.png';
    if (file.type.match(/(wav)$/i)) return './wav_placeholder.png';
    if (file.type.match(/(aac)$/i)) return './aac_placeholder.png';
    if (file.type.match(/(txt)$/i)) return './txt_placeholder.png';
    if (file.type.match(/(heic)$/i)) return './heic_placeholder.png';
    if (file.type.match(/(docx)$/i)) return './docx_placeholder.png';
    if (file.type.match(/(xlsx)$/i)) return './xlsx_placeholder.png';
    if (file.type.match(/(ppt)$/i)) return './ppt_placeholder.png';
    if (file.type.match(/(json)$/i)) return './json_placeholder.png';


    return file.url;
  };
  const moveSelectedFilesToFolder = async (targetFolder) => {
    if (selectedFiles.length === 0) {
      alert('No files selected to move.');
      return;
    }

    try {
      const confirmed = window.confirm('Warning: Moving files will break any shared links. Are you sure you want to proceed?');
      if (!confirmed) return;

      for (const file of selectedFiles) {
        // Define source and target URLs
        const sourceUrl = `${containerUrl}/${encodeURIComponent(tenant)}/${encodeURIComponent(file.name)}`;
        const targetUrl = `${containerUrl}/${encodeURIComponent(targetFolder)}/${encodeURIComponent(file.name)}`;

        const sourceUrlWithSas = `${sourceUrl}?${sasToken}`;
        const targetUrlWithSas = `${targetUrl}?${sasToken}`;

        // Perform the copy operation
        const copyResponse = await fetch(targetUrlWithSas, {
          method: 'PUT',
          headers: {
            'x-ms-copy-source': sourceUrlWithSas,
            'x-ms-blob-type': 'BlockBlob',
          },
        });

        if (!copyResponse.ok) {
          throw new Error(`Failed to move file: ${file.name}`);
        }

        // Delete the original file after a successful copy
        const deleteResponse = await fetch(sourceUrlWithSas, { method: 'DELETE' });
        if (!deleteResponse.ok) {
          throw new Error(`Failed to delete original file: ${file.name}`);
        }
      }

      alert('Files moved successfully, but make sure the links are updated!');
      await fetchData(); // Reload files after moving
      setSelectedFiles([]);
    } catch (e) {
      console.error('Error moving files:', e);
      setError(`Error moving files: ${e.message}`);
    }
  };

  const handleFileSelection = (fileName, isSelected, isFolder = false) => {
    if (isSelected) {
      setSelectedFiles((prev) => [
        ...prev,
        isFolder ? folders.find((folder) => folder.name === fileName) : files.find((file) => file.name === fileName),
      ]);
    } else {
      setSelectedFiles((prev) => prev.filter((item) => item.name !== fileName));
    }
  };

  const handleFolderClick = (folderName) => {
    if (!folderName) {
      console.error('Folder name is undefined or invalid');
      return;
    }
    const newTenantPath = tenant ? `${tenant}/${folderName}` : folderName;
    setTenant(newTenantPath);
  };

  const handleBackClick = () => {
    if (!tenant.includes('/')) {
      setTenant(''); // If at root level, reset tenant
    } else {
      const parentFolder = tenant.split('/').slice(0, -1).join('/');
      setTenant(parentFolder);
    }
  };

  const handleUseWithNYX = () => {
    const selectedFileUrls = selectedFiles.map((file) => file.url);
    const selectedFileNames = selectedFiles.map((file) => file.name);
    navigate('/use-with-nyx', { state: { selectedFileUrls, selectedFileNames } });
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const filteredFiles = files.filter((file) =>
      file.name.toLowerCase().includes(nameFilter.toLowerCase())
  );
  const currentFiles = filteredFiles.slice(indexOfFirstItem, indexOfLastItem);

  const createFolder = async () => {
    if (!newFolderName) {
      alert('Please enter a folder name.');
      return;
    }

    try {
      const folderUrl = `${containerUrl}/${tenant}/${newFolderName}/?${sasToken}`;
      const response = await fetch(folderUrl, {
        method: 'PUT',
        headers: {
          'x-ms-blob-type': 'BlockBlob',
        },
      });

      if (response.ok) {
        alert(`Folder "${newFolderName}" created successfully under tenant "${tenant}".`);
        setFolders([...folders, { name: newFolderName, isFolder: true }]);
        setNewFolderName('');
      } else {
        throw new Error(`Failed to create folder with status ${response.status}`);
      }
    } catch (e) {
      setError(`Failed to create folder: ${e.message}`);
    }
  };

  const handleDeleteSelected = async () => {
    if (selectedFiles.length === 0) {
      alert('No files or folders selected for deletion.');
      return;
    }

    try {
      const confirmed = window.confirm('Are you sure you want to delete the selected items? Deleting files can cause broken links if you have shared them or used them in web pages.');
      if (!confirmed) return;

      for (const item of selectedFiles) {
        const deleteUrl = `${containerUrl}/${encodeURIComponent(tenant)}/${encodeURIComponent(item.name)}?${sasToken}`;
        const response = await fetch(deleteUrl, { method: 'DELETE' });

        if (!response.ok) {
          if (response.status === 404) {
            alert(`File not found: ${item.name}`);
          } else {
            throw new Error(`Failed to delete ${item.name}`);
          }
        }
      }

      await fetchData();
      setSelectedFiles([]);
      alert('Selected items have been deleted.');
    } catch (error) {
      console.error('Error deleting items:', error);
      setError(`Error deleting items: ${error.message}`);
    }
  };

  const proceedWithDelete = async () => {
    try {
      for (const item of selectedFiles) {
        const encodedFileName = encodeURIComponent(item.name);
        const deleteUrl = `${containerUrl}/${encodeURIComponent(tenant)}/${encodedFileName}?${sasToken}`;

        const response = await fetch(deleteUrl, { method: 'DELETE' });
        if (!response.ok) {
          throw new Error(`Failed to delete ${item.name}`);
        }
      }

      await fetchData();
      setSelectedFiles([]);
      setShowWarningModal(false);
    } catch (error) {
      console.error('Error deleting items:', error);
      setError(`Error deleting items: ${error.message}`);
    }
  };

  const handleCopyLink = (url) => {
    navigator.clipboard.writeText(url)
        .then(() => {
          setShowModal(true);
          setTimeout(() => setShowModal(false), 3000);
        })
        .catch((err) => setError('Failed to copy link.'));
  };

  return (


      <div>
        <div className="help-chat-button-section">
          <button tabIndex="0"
                  className="help-chat-button"
                  onClick={toggleHelpChat}
          >
            Need Help? 💬
          </button>

          {isHelpChatOpen && (
              <div className="help-chat-popup">
                <button tabIndex="0" className="close-help-chat" onClick={toggleHelpChat}>
                  CLOSE HELP ✖
                </button>
                <iframe
                    src="https://help.nyx.baby"
                    title="NYX Help Chat"
                    className="help-chat-iframe"
                    frameBorder="0"
                />
              </div>
          )}
        </div>

        <button className={"magical-button"} tabIndex={"a0"} onClick={handleUseWithNYX} title="Create Now">✨🌟Create Now!🌟✨</button>

        {error && <p className="error">{error}</p>}
        <div className="tenant-input-container">
          <p className="tenant">Signed In as</p>
          <input value={tenant} onChange={handleTenantChange} placeholder="Enter Your Name" disabled={!!userName} />
        </div>

        <button
            onClick={handleBackClick}
            className={`back-button ${tenant.split('/').length <= 1 ? 'disabled' : ''}`}
            disabled={tenant.split('/').length <= 1}
        >
          ← Back
        </button>
        <div className="file-gallery-controls">
          <button onClick={selectAllVisibleFiles}>
            {currentFiles.every(file => selectedFiles.includes(file)) ? "Deselect All" : "Select All"}
          </button>
        </div>
        <div className="file-gallery">
          {folders.map((folder, index) => (
              <div key={`folder-${index}`} className="file-item">
                <input
                    type="checkbox"
                    checked={selectedFiles.some((item) => item.name === folder.name)}
                    onChange={(e) => handleFileSelection(folder.name, e.target.checked, true)}
                    onClick={(e) => e.stopPropagation()}
                />
                <div onClick={() => handleFolderClick(folder.name)} className="folder-content">
                  <img src={foldericon} alt={folder.name} className="file-thumbnail" />
                  <span className="file-name">{folder.name}</span>
                </div>
              </div>
          ))}

          {currentFiles.map((file, index) => (
              <div key={`file-${index}`} className="file-item">
                <input
                    type="checkbox"
                    checked={selectedFiles.includes(file)}
                    onChange={(e) => handleFileSelection(file.name, e.target.checked)}
                />
                <a href={file.url} target="_blank" rel="noopener noreferrer">
                  <img src={getFileThumbnail(file)} alt={file.name} className="file-thumbnail" />
                </a>
                <a href={file.url} target="_blank" rel="noopener noreferrer" className="file-name" title={file.name}>
                  {file.name}
                </a>
                <button onClick={() => handleCopyLink(file.url)}>Copy Link</button>
              </div>
          ))}
        </div>

        {showModal && (
            <div className="modal">
              <p>Copied Link to Clipboard!</p>
              <p>Make sure you don't move or delete files after you share them, or your links will break!</p>
            </div>
        )}

        {showWarningModal && (
            <div className="modal">
              <p>Warning: Deleting these files will break any shared links!</p>
              <p>Once deleted, any links pointing to these files will no longer work. Are you sure you want to proceed?</p>
              <div>
                <button onClick={proceedWithDelete}>Proceed with Deletion</button>
                <button onClick={() => setShowWarningModal(false)}>Cancel</button>
              </div>
            </div>
        )}
        <button className="delete-selected" onClick={handleDeleteSelected} tabIndex={"0"}>⚠️ Delete Selected Files</button>
        {error && <p>{error}</p>}
        <button tabIndex={"a0"} onClick={handleUseWithNYX} title="Create a Page with Selected Files">Create a Page with Selected Files</button>
        <div className="filter-container-box">
        <div className="filter-container">
          <div className="pagination-controls">
          <img src={Magnify} alt={"magnifying glass"} />
            <input
                className="file-search"
                type="text"
                value={nameFilter}
                onChange={(e) => setNameFilter(e.target.value)}
                placeholder="Filter by filename"
            />
            <div className="items-per-page-selector">
              <label htmlFor="itemsPerPage">Items per page:</label>
              <select
                  id="itemsPerPage"
                  value={itemsPerPage}
                  onChange={(e) => setItemsPerPage(Number(e.target.value))}
              >
                <option value="1">1</option>
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
            <button onClick={fetchData}>Reload Files</button>
            <button
                onClick={() => setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))}
                disabled={currentPage === 1}
            >
              Previous
            </button>
            <button
                onClick={() => setCurrentPage((prevPage) => Math.min(prevPage + 1, Math.ceil(files.length / itemsPerPage)))}
                disabled={currentPage === Math.ceil(files.length / itemsPerPage)}
            >
              Next
            </button>
          </div>
        </div>
        </div>
        <FileUploader />
        <div className="folder-creation">
          <input
              type="text"
              value={newFolderName}
              onChange={(e) => setNewFolderName(e.target.value)}
              placeholder="Enter the name of the new folder you want to create"
          />
          <button onClick={createFolder}>Create Folder</button>
        </div>

        <div>
          <div>



            {/* Select Folder to Move File Into */}
            <div className={"folder-move"}>
              <FolderSelector
                  tenant={tenant}
                  containerUrl={containerUrl}
                  sasToken={sasToken}
                  onFolderSelect={(folderPath) => {
                    // Remove the separate move button and move the files directly
                    const isMovingToRoot = folderPath === tenant;
                    moveSelectedFilesToFolder(isMovingToRoot ? '/' : folderPath);
                  }}
                  currentPath={tenant}
                  selectedFiles={selectedFiles}  // Pass selected files
              />


          </div>
        </div>
      </div>
      </div>
  );
}


export default TenantFileGallery;
