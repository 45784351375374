import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { MsalProvider, useMsal, useIsAuthenticated } from "@azure/msal-react";
import msalInstance from "./authConfig";
import TenantFileGallery from './TenantFileGallery';
import UseWithNyxPage from './UseWithNyxPage';
import JudgesEvaluationForm from "./JudgesEvaluationForm";
import SignInImage from "./ms_signin_dark.png";
import SignOutButton from "./SignOutButton";
import './App.css';

function SignInButton() {
    const { instance } = useMsal();
    const handleLogin = () => {
        instance.loginRedirect().catch(e => {
            console.error(e);
        });
    };
    return <button className="msbutton" onClick={handleLogin}><img src={SignInImage} alt="Sign in to NYX NoCode with Microsoft" /></button>;
}

function AppContent() {
    const isAuthenticated = useIsAuthenticated();
    const { accounts } = useMsal();
    const isDevelopment = process.env.NODE_ENV === 'development';
    const userName = isDevelopment ? "kilroy@uark.edu" : accounts?.[0]?.username;

    const requireAuth = (Component) => {
        return isAuthenticated || isDevelopment ? Component : <SignInButton />;
    };

    return (
        <div className="app-container">
            {/* Show SignOutButton only if authenticated */}
            {(isAuthenticated || isDevelopment) && (
                <div className="signout-container">
                    <SignOutButton />
                </div>
            )}
            <Routes>
                <Route
                    path="/"
                    element={requireAuth(<TenantFileGallery userName={userName} />)}
                />
                <Route
                    path="/use-with-nyx"
                    element={requireAuth(<UseWithNyxPage />)}
                />
                <Route
                    path="/judges-evaluation"
                    element={requireAuth(<JudgesEvaluationForm userName={userName} />)}
                />
            </Routes>
        </div>
    );
}

function App() {
    return (
        <MsalProvider instance={msalInstance}>
            <Router>
                <AppContent />
            </Router>
        </MsalProvider>
    );
}

export default App;
