// authConfig.js
import { PublicClientApplication } from "@azure/msal-browser";

const msalConfig = {
    auth: {
        clientId: "dffa9f47-41e4-4c62-81e8-fbaa306490b2",
        authority: "https://login.microsoftonline.com/dd7ed090-c589-441a-a4db-24361e4bfccb",
        redirectUri: "https://nocode.nyx.baby"

    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true,
    }
};

const msalInstance = new PublicClientApplication(msalConfig);

export default msalInstance;
