import React, { useRef, useState, useEffect } from 'react';
import './DoodlePad.css'

function DoodlePad({ onDoodleSubmit }) {
    // Canvas and drawing state
    const canvasRef = useRef(null);
    const [isDrawing, setIsDrawing] = useState(false);

    // Drawing properties
    const [currentColor, setCurrentColor] = useState('#000000');
    const [lineWidth, setLineWidth] = useState(2);
    const [opacity, setOpacity] = useState(1);
    const [lineStyle, setLineStyle] = useState('solid');

    // Effect modes
    const [isRainbowMode, setIsRainbowMode] = useState(false);
    const [isMirrorMode, setIsMirrorMode] = useState(false);
    const [isKaleidoscopeMode, setIsKaleidoscopeMode] = useState(false);
    const [isGlowMode, setIsGlowMode] = useState(false);
    const [isSpiroMode, setIsSpiroMode] = useState(false);
    const [activeTool, setActiveTool] = useState('draw'); // 'draw' or 'stamp'

    // Spirograph properties
    const [spiroSize, setSpiroSize] = useState(50);
    const [spiroSpeed, setSpiroSpeed] = useState(5);
    const spiroRef = useRef(null);
    const rainbowIndex = useRef(0);

    // Shape stamp properties
    const [selectedShape, setSelectedShape] = useState('circle'); // 'circle', 'square', 'triangle'
    const [shapeSize, setShapeSize] = useState(50);

    // Initialize canvas with white background
    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext("2d");
        ctx.fillStyle = "white";
        ctx.fillRect(0, 0, canvas.width, canvas.height);
    }, []);

    // Cleanup spirograph animation on unmount
    useEffect(() => {
        return () => {
            if (spiroRef.current) {
                cancelAnimationFrame(spiroRef.current);
            }
        };
    }, []);

    const getRainbowColor = () => {
        const rainbow = ['#FF0000', '#FF7F00', '#FFFF00', '#00FF00', '#0000FF', '#4B0082', '#8F00FF'];
        rainbowIndex.current = (rainbowIndex.current + 1) % rainbow.length;
        return rainbow[rainbowIndex.current];
    };

    const drawMirror = (ctx, x, y) => {
        const centerX = ctx.canvas.width / 2;
        const mirroredX = centerX + (centerX - x);
        ctx.lineTo(mirroredX, y);
    };

    const drawKaleidoscope = (ctx, x, y) => {
        const centerX = ctx.canvas.width / 2;
        const centerY = ctx.canvas.height / 2;
        const points = 8;

        for (let i = 0; i < points; i++) {
            const angle = (Math.PI * 2 * i) / points;
            const dx = x - centerX;
            const dy = y - centerY;
            const rotatedX = centerX + dx * Math.cos(angle) - dy * Math.sin(angle);
            const rotatedY = centerY + dx * Math.sin(angle) + dy * Math.cos(angle);
            ctx.lineTo(rotatedX, rotatedY);
        }
    };

    const drawSpirograph = (startX, startY) => {
        if (!isSpiroMode) return;

        const canvas = canvasRef.current;
        const ctx = canvas.getContext("2d");

        if (spiroRef.current) {
            cancelAnimationFrame(spiroRef.current);
        }

        let angle = 0;
        let innerRadius = spiroSize / 1;
        let outerRadius = spiroSize;
        let x0 = startX;
        let y0 = startY;

        const animate = () => {
            const x = x0 + (outerRadius - innerRadius) * Math.cos(angle) +
                innerRadius * Math.cos(((outerRadius - innerRadius) / innerRadius) * angle);
            const y = y0 + (outerRadius - innerRadius) * Math.sin(angle) -
                innerRadius * Math.sin(((outerRadius - innerRadius) / innerRadius) * angle);

            if (angle === 0) {
                ctx.beginPath();
                ctx.moveTo(x, y);
            } else {
                ctx.lineTo(x, y);
                ctx.stroke();
            }

            angle += (spiroSpeed / 100);

            if (angle <= Math.PI * 20) {
                spiroRef.current = requestAnimationFrame(animate);
            }
        };

        ctx.lineWidth = lineWidth;
        ctx.strokeStyle = isRainbowMode ? getRainbowColor() : currentColor;
        if (isGlowMode) {
            ctx.shadowBlur = 10;
            ctx.shadowColor = ctx.strokeStyle;
        }

        animate();
    };

    const handlePointerMove = (event) => {
        if (!isDrawing || activeTool !== 'draw') return;
        const canvas = canvasRef.current;
        const ctx = canvas.getContext("2d");

        ctx.lineWidth = lineWidth;
        ctx.globalAlpha = opacity;

        if (lineStyle === 'dotted') {
            ctx.setLineDash([5, 5]);
        } else if (lineStyle === 'dashed') {
            ctx.setLineDash([15, 5]);
        } else {
            ctx.setLineDash([]);
        }

        if (isGlowMode) {
            ctx.shadowBlur = 10;
            ctx.shadowColor = currentColor;
        } else {
            ctx.shadowBlur = 0;
        }

        const rect = canvas.getBoundingClientRect();
        const x = (event.clientX || event.touches[0].clientX) - rect.left;
        const y = (event.clientY || event.touches[0].clientY) - rect.top;

        if (isRainbowMode) {
            ctx.strokeStyle = getRainbowColor();
        } else {
            ctx.strokeStyle = currentColor;
        }

        ctx.lineTo(x, y);
        ctx.stroke();
        ctx.beginPath();
        ctx.moveTo(x, y);

        if (isMirrorMode) {
            ctx.beginPath();
            ctx.moveTo(x, y);
            drawMirror(ctx, x, y);
            ctx.stroke();
        }

        if (isKaleidoscopeMode) {
            ctx.beginPath();
            ctx.moveTo(x, y);
            drawKaleidoscope(ctx, x, y);
            ctx.stroke();
        }
    };

    const handlePointerDown = (event) => {
        setIsDrawing(true);
        const canvas = canvasRef.current;
        const ctx = canvas.getContext("2d");
        const rect = canvas.getBoundingClientRect();
        const x = (event.clientX || event.touches[0].clientX) - rect.left;
        const y = (event.clientY || event.touches[0].clientY) - rect.top;

        if (activeTool === 'stamp') {
            drawShape(ctx, x, y);
        } else if (isSpiroMode) {
            drawSpirograph(x, y);
        } else {
            ctx.beginPath();
            ctx.moveTo(x, y);
        }
    };

    const handlePointerUp = () => {
        setIsDrawing(false);
        setActiveTool('draw'); // Reset to draw tool after stamping
        const canvas = canvasRef.current;
        const doodleImage = canvas.toDataURL();
        onDoodleSubmit(doodleImage);
    };

    const handlePointerLeave = () => {
        setIsDrawing(false);
    };

    const clearCanvas = () => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext("2d");
        ctx.fillStyle = "white";
        ctx.fillRect(0, 0, canvas.width, canvas.height);
    };

    const drawShape = (ctx, x, y) => {
        ctx.beginPath();
        ctx.lineWidth = lineWidth;
        ctx.strokeStyle = isRainbowMode ? getRainbowColor() : currentColor;
        ctx.globalAlpha = opacity;

        if (isGlowMode) {
            ctx.shadowBlur = 10;
            ctx.shadowColor = ctx.strokeStyle;
        } else {
            ctx.shadowBlur = 0;
        }

        switch (selectedShape) {
            case 'circle':
                ctx.arc(x, y, shapeSize / 2, 0, Math.PI * 2);
                break;
            case 'square':
                ctx.rect(x - shapeSize / 2, y - shapeSize / 2, shapeSize, shapeSize);
                break;
            case 'triangle':
                ctx.moveTo(x, y - shapeSize / 2);
                ctx.lineTo(x + shapeSize / 2, y + shapeSize / 2);
                ctx.lineTo(x - shapeSize / 2, y + shapeSize / 2);
                ctx.closePath();
                break;
            default:
                break;
        }

        ctx.stroke();
    };

    return (
        <div className="doodle-pad">
            {/* Color Controls */}
            <div className="control-section">
                <label htmlFor="color-picker" style={{ marginRight: '10px' }}>Choose Color:</label>
                <input
                    id="color-picker"
                    type="color"
                    value={currentColor}
                    onChange={(e) => setCurrentColor(e.target.value)}
                    style={{ width: '50px', height: '50px', padding: '0', border: 'none', cursor: 'pointer' }}
                />
                <div className="quick-colors" style={{ display: 'inline-block', marginLeft: '10px' }}>
                    {['#000000', '#FF0000', '#00FF00', '#0000FF', '#FFFF00', '#FF00FF'].map(color => (
                        <button
                            key={color}
                            onClick={() => setCurrentColor(color)}
                            style={{
                                backgroundColor: color,
                                width: '30px',
                                height: '30px',
                                margin: '0 5px',
                                border: currentColor === color ? '3px solid #666' : '2px solid #999',
                                borderRadius: '50%',
                                cursor: 'pointer'
                            }}
                            aria-label={`Select color ${color}`}
                        />
                    ))}
                </div>
            </div>

            {/* Drawing Controls */}
            <div className="control-section">
                <label style={{ marginRight: '10px' }}>
                    Line Width:
                    <input
                        type="range"
                        min="1"
                        max="50"
                        value={lineWidth}
                        onChange={(e) => setLineWidth(parseInt(e.target.value))}
                        style={{ marginLeft: '10px' }}
                    />
                </label>

                <label style={{ marginRight: '10px' }}>
                    Opacity:
                    <input
                        type="range"
                        min="0.1"
                        max="1"
                        step="0.1"
                        value={opacity}
                        onChange={(e) => setOpacity(parseFloat(e.target.value))}
                        style={{ marginLeft: '10px' }}
                    />
                </label>
            </div>

            {/* Special Effects */}
            <div className="control-section">
                <button
                    onClick={() => {
                        setIsRainbowMode(!isRainbowMode);
                        setActiveTool('draw');
                    }}
                    style={{
                        background: isRainbowMode ? 'linear-gradient(to right, red,orange,yellow,green,blue,indigo,violet)' : '',
                        padding: '10px',
                        margin: '5px',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        fontSize: '16px'
                    }}
                    aria-pressed={isRainbowMode}
                >
                    🌈 Rainbow Mode
                </button>
                <button
                    onClick={() => {
                        setIsMirrorMode(!isMirrorMode);
                        setActiveTool('draw');
                    }}
                    style={{
                        backgroundColor: isMirrorMode ? '#a0a0ff' : '',
                        padding: '10px',
                        margin: '5px',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        fontSize: '16px'
                    }}
                    aria-pressed={isMirrorMode}
                >
                    🪞 Mirror Draw
                </button>
                <button
                    onClick={() => {
                        setIsKaleidoscopeMode(!isKaleidoscopeMode);
                        setActiveTool('draw');
                    }}
                    style={{
                        backgroundColor: isKaleidoscopeMode ? '#ffa0ff' : '',
                        padding: '10px',
                        margin: '5px',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        fontSize: '16px'
                    }}
                    aria-pressed={isKaleidoscopeMode}
                >
                    ❄️ Kaleidoscope
                </button>
                <button
                    onClick={() => {
                        setIsGlowMode(!isGlowMode);
                        setActiveTool('draw');
                    }}
                    style={{
                        backgroundColor: isGlowMode ? '#ffff80' : '',
                        padding: '10px',
                        margin: '5px',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        fontSize: '16px'
                    }}
                    aria-pressed={isGlowMode}
                >
                    ✨ Glow Effect
                </button>
                <button
                    onClick={() => {
                        setIsSpiroMode(!isSpiroMode);
                        setActiveTool('draw');
                    }}
                    style={{
                        backgroundColor: isSpiroMode ? '#ffd700' : '',
                        padding: '10px',
                        margin: '5px',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        fontSize: '16px'
                    }}
                    aria-pressed={isSpiroMode}
                >
                    🌀 Spirograph
                </button>
            </div>

            {/* Line Style */}
            <div className="control-section">
                <label htmlFor="line-style" style={{ marginRight: '10px' }}>Line Style:</label>
                <select
                    id="line-style"
                    value={lineStyle}
                    onChange={(e) => setLineStyle(e.target.value)}
                    style={{ padding: '5px', fontSize: '16px' }}
                >
                    <option value="solid">Solid Line</option>
                    <option value="dotted">Dotted Line</option>
                    <option value="dashed">Dashed Line</option>
                </select>
            </div>

            {/* Shape Stamp Controls */}
            <div className="control-section">
                <button
                    onClick={() => setActiveTool(activeTool === 'stamp' ? 'draw' : 'stamp')}
                    style={{
                        backgroundColor: activeTool === 'stamp' ? '#cceeff' : '',
                        padding: '10px',
                        margin: '5px',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        fontSize: '16px'
                    }}
                    aria-pressed={activeTool === 'stamp'}
                >
                    🟢 Shape Stamp
                </button>
                {activeTool === 'stamp' && (
                    <>
                        <label style={{ marginLeft: '10px', marginRight: '10px' }}>
                            Shape:
                            <select
                                value={selectedShape}
                                onChange={(e) => setSelectedShape(e.target.value)}
                                style={{ marginLeft: '5px', padding: '5px', fontSize: '16px' }}
                            >
                                <option value="circle">Circle</option>
                                <option value="square">Square</option>
                                <option value="triangle">Triangle</option>
                            </select>
                        </label>
                        <label>
                            Size:
                            <input
                                type="range"
                                min="20"
                                max="150"
                                value={shapeSize}
                                onChange={(e) => setShapeSize(parseInt(e.target.value))}
                                style={{ marginLeft: '5px' }}
                            />
                        </label>
                    </>
                )}
            </div>

            {/* Spirograph Controls */}
            {isSpiroMode && (
                <div className="control-section spiro-controls">
                    <label style={{ marginRight: '10px' }}>
                        Size:
                        <input
                            type="range"
                            min="20"
                            max="150"
                            value={spiroSize}
                            onChange={(e) => setSpiroSize(parseInt(e.target.value))}
                            style={{ marginLeft: '10px' }}
                        />
                    </label>
                    <label>
                        Speed:
                        <input
                            type="range"
                            min="1"
                            max="10"
                            value={spiroSpeed}
                            onChange={(e) => setSpiroSpeed(parseInt(e.target.value))}
                            style={{ marginLeft: '10px' }}
                        />
                    </label>
                </div>
            )}

            {/* Canvas */}
            <canvas
                ref={canvasRef}
                width={400}
                height={400}
                onMouseMove={handlePointerMove}
                onMouseDown={handlePointerDown}
                onMouseUp={handlePointerUp}
                onMouseLeave={handlePointerLeave}
                onTouchMove={handlePointerMove}
                onTouchStart={handlePointerDown}
                onTouchEnd={handlePointerUp}
                style={{
                    border: "2px solid #333",
                    backgroundColor: "white",
                    cursor: activeTool === 'stamp' ? 'pointer' : 'crosshair',
                    touchAction: 'none' // Prevents touch scrolling while drawing
                }}
            />

            {/* Action Buttons */}
            <div className="button-section" style={{ marginTop: '10px' }}>
                <button
                    onClick={clearCanvas}
                    style={{
                        padding: '10px 20px',
                        margin: '5px',
                        fontSize: '16px',
                        cursor: 'pointer',
                        background: 'pink',
                        borderRadius: '5px',
                        color: 'black'
                    }}
                >
                    ❌ Clear
                </button>
                <button
                    onClick={handlePointerUp}
                    style={{
                        padding: '10px 20px',
                        margin: '5px',
                        fontSize: '16px',
                        cursor: 'pointer',
                        borderRadius: '5px'
                    }}
                >
                    ✨🤖🧠 Analyze Doodle
                </button>
            </div>
        </div>
    );
}

export default DoodlePad;
