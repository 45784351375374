// VisionAnalysisComponent.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';

const VisionAnalysisComponent = ({ imageUrl, setImageUrl, handleResult }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    // **Trigger analysis when imageUrl changes**
    useEffect(() => {
        if (imageUrl) {
            analyzeImage();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [imageUrl]);

    const analyzeImage = async () => {
        if (!imageUrl) {
            setError('No image URL provided.');
            return;
        }

        setLoading(true);
        setError(''); // Clear any previous errors

        const endpoint = 'https://filebabyazureaisearch.cognitiveservices.azure.com/computervision/imageanalysis:analyze?features=caption,read&model-version=latest&language=en&api-version=2024-02-01';
        const subscriptionKey = '2c27d62ed18d4b03af03d9aa4b9bfa2c';  // **Security Note:** Avoid hardcoding API keys in client-side code.

        try {
            console.log('Analyzing Image URL:', imageUrl);

            const response = await axios.post(
                endpoint,
                { url: imageUrl },
                {
                    headers: {
                        'Ocp-Apim-Subscription-Key': subscriptionKey,
                        'Content-Type': 'application/json',
                    },
                }
            );

            // Log the successful response
            console.log('Analysis Response:', response.data);

            // Extract the caption from captionResult
            const result = response.data.captionResult?.text || 'No description available';
            handleResult(result); // Send the result back to the parent
        } catch (error) {
            // Log full error details to diagnose the issue
            console.error('Error analyzing image:', error.response?.data || error.message);

            // Display a user-friendly message
            setError('Error analyzing image. Please verify the image URL and try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleInputChange = (e) => {
        setImageUrl(e.target.value);
        handleResult(''); // Clear previous result when input changes
    };

    return (
        <div style={{ marginTop: '20px' }}>
            <input
                type="text"
                value={imageUrl}
                onChange={handleInputChange}
                placeholder="Enter image URL"
                style={{ width: '300px', padding: '5px' }}
            />
            <button onClick={analyzeImage} style={{ marginLeft: '10px', padding: '5px 10px' }}>
                🧠 Analyze Image
            </button>
            {loading && <p>Analyzing image...</p>}
            {error && <p style={{ color: 'red' }}>{error}</p>}
        </div>
    );
};

export default VisionAnalysisComponent;
