// ChatbotNYX.js
import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import './ChatbotNYX.css';
import VisionAnalysisComponent from './VisionAnalysisComponent';
import chatConfig from './ChatSetup-NYX.json';
import { SearchClient, AzureKeyCredential } from "@azure/search-documents";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAudio, faFileVideo, faFileCode, faFileImage } from '@fortawesome/free-solid-svg-icons';
import DoodlePad from './DoodlePad';
import SavedItem from './SavedItem'; // Import SavedItem component

const searchServiceName = "nyxsearch724482774264";
const indexName = "nyx-index";
const searchApiKey = process.env.REACT_APP_AZURE_SEARCH_API_KEY;
const searchEndpoint = `https://${searchServiceName}.search.windows.net`;

const searchClient = searchApiKey ? new SearchClient(searchEndpoint, indexName, new AzureKeyCredential(searchApiKey)) : null;

const ChatbotNYX = ({ userName, setPageContent, pageContent, selectedFileUrls, includeFilesInChat, setIncludeFilesInChat }) => {
    const [prompt, setPrompt] = useState('');
    const [lastUsedPrompt, setLastUsedPrompt] = useState(''); // Add this new state
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [savedFileLink, setSavedFileLink] = useState('');
    const [error, setError] = useState('');
    const [completionMessage, setCompletionMessage] = useState('');
    const [flashSaveButton, setFlashSaveButton] = useState(false);
    const [showDoodlePad, setShowDoodlePad] = useState(false);
    const responseEndRef = useRef(null);

    const containerUrl = 'https://drive.nyx.baby/nyxnocode';
    const sasToken = process.env.REACT_APP_SAS_TOKEN;

    // **Add imageFileLink and imageUrl states**
    const [imageFileLink, setImageFileLink] = useState('');
    const [imageUrl, setImageUrl] = useState('');

    const [isHelpChatOpen, setIsHelpChatOpen] = useState(false);

    const toggleHelpChat = () => {
        setIsHelpChatOpen(!isHelpChatOpen);
    };

    const handleImageAnalysisResult = (result) => {
        if (result) {
            setPrompt(prevPrompt => `${prevPrompt}\n${result}`);
        }
    };

    const [savedPrompts, setSavedPrompts] = useState([]);  // Saved prompts from Azure Blob


    const handleInputChange = (event) => {
        setPrompt(event.target.value);
    };

    const createPromptSnippet = (inputPrompt) => {
        return inputPrompt
            .trim()
            .slice(0, 80)
            .replace(/\s+/g, '_')
            .replace(/[^a-zA-Z0-9_]/g, '');
    };

    const savePromptAsFile = async () => {
        const trimmedPrompt = prompt.trim();

        if (!trimmedPrompt) {
            setError('Prompt input is empty.');
            return;
        }

        // Create timestamp
        const now = new Date();
        const date = now.toISOString().split('T')[0].replace(/-/g, ''); // YYYYMMDD format
        const time = now.toTimeString().split(' ')[0].replace(/:/g, ''); // HHMMSS format
        const dateTimeStamp = `${date}-${time}`;

        const promptSnippet = createPromptSnippet(trimmedPrompt);
        const fileName = `${promptSnippet}-${dateTimeStamp}-prompt.txt`;

        // Create a Blob from the prompt content (this is your promptFile)
        const promptFile = new Blob([trimmedPrompt], { type: 'text/plain' });

        try {
            const filePath = `${containerUrl}/${userName}/${fileName}?${sasToken}`;
            const response = await fetch(filePath, {
                method: 'PUT',
                headers: {
                    'x-ms-blob-type': 'BlockBlob',
                    'Content-Type': 'text/plain',
                },
                body: promptFile, // Use the Blob as the file content here
            });

            if (!response.ok) {
                throw new Error(`Failed to save file ${fileName} with status: ${response.status}`);
            }

            console.log('Prompt saved successfully:', fileName);
            fetchSavedPrompts();  // Refresh the list of saved prompts after saving
        } catch (error) {
            console.error('Error saving prompt:', error);
            setError(`Error saving prompt: ${error.message}`);
        }
    };


    // Fetch saved prompts
    const fetchSavedPrompts = useCallback(async () => {
        try {
            const listUrl = `${containerUrl}?restype=container&comp=list&${sasToken}`;
            const response = await fetch(listUrl);
            if (!response.ok) {
                throw new Error(`Failed to fetch blob list. Status: ${response.status}`);
            }
            const text = await response.text();
            const parser = new DOMParser();
            const xml = parser.parseFromString(text, 'application/xml');
            const blobItems = Array.from(xml.getElementsByTagName('Blob'));

            // Filter blobs by the current user's folder and .txt extension
            const blobs = blobItems
                .map(blob => blob.getElementsByTagName('Name')[0].textContent)
                .filter(name => name.startsWith(`${userName}/`) && name.endsWith('.txt'));

            setSavedPrompts(blobs); // Update state with the blob names
        } catch (error) {
            console.error('Error fetching saved prompts:', error);
            setError('Failed to fetch saved prompts.');
        }
    }, [containerUrl, sasToken, userName]);


    // Load a selected prompt into the input field
    const loadPrompt = async (promptFileName) => {
        if (!promptFileName) {
            setError("No prompt file selected.");
            return;
        }

        try {
            // Correct the URL construction by concatenating only once
            const promptUrl = `${containerUrl}/${promptFileName}?${sasToken}`;
            console.log('Attempting to load prompt from URL:', promptUrl);

            const response = await fetch(promptUrl);

            if (!response.ok) {
                console.error(`Error fetching prompt. Status: ${response.status}`);
                throw new Error(`Failed to fetch prompt. Status: ${response.status}`);
            }

            const promptContent = await response.text();
            setPrompt(promptContent);
        } catch (error) {
            console.error('Error loading prompt:', error);
            setError('Error: Prompt not found. Please verify the filename and try again.');
        }
    };


    useEffect(() => {
        fetchSavedPrompts();  // Load saved prompts when component mounts
    }, [fetchSavedPrompts]);


    const getFileIcon = (url) => {
        const extension = url.split('.').pop().toLowerCase();
        switch (extension) {
            case 'mp3':
            case 'wav':
            case 'ogg':
                return <FontAwesomeIcon icon={faFileAudio} className="file-icon" />;
            case 'mp4':
            case 'avi':
            case 'mov':
                return <FontAwesomeIcon icon={faFileVideo} className="file-icon" />;
            case 'html':
            case 'htm':
                return <FontAwesomeIcon icon={faFileCode} className="file-icon" />;
            case 'jpg':
            case 'jpeg':
            case 'png':
            case 'gif':
                return <img src={url} alt="Selected File" className="thumbnail" />;
            default:
                return <FontAwesomeIcon icon={faFileImage} className="file-icon" />;
        }
    };

    const retryRequest = async (requestFn, retries = 3, delay = 1000) => {
        let attempt = 0;
        while (attempt < retries) {
            try {
                return await requestFn();
            } catch (error) {
                attempt++;
                if (attempt >= retries) throw error;
                await new Promise(resolve => setTimeout(resolve, delay));
            }
        }
    };

    const rewordPrompt = async (originalPrompt) => {
        const rewordEndpoint = "https://nyx.openai.azure.com/openai/deployments/nyx/chat/completions?api-version=2024-05-01-preview";
        const headers = {
            'api-key': process.env.REACT_APP_OPENAI_API_KEY,
            'Content-Type': 'application/json'
        };
        const data = {
            model: "nyx",
            messages: [
                { role: "system", content: "You are an assistant that helps reword prompts to be more effective." },
                { role: "user", content: `Please reword this prompt to make it more effective: ${originalPrompt}` }
            ],
            max_tokens: 50,
            temperature: 0.7,
        };
        try {
            const response = await axios.post(rewordEndpoint, data, { headers });
            return response.data.choices[0].message.content;
        } catch (error) {
            console.error('Error rewording prompt:', error);
            return originalPrompt;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Trim the prompt before proceeding
        const trimmedPrompt = prompt.trim();
        console.log('Submit - Current prompt:', trimmedPrompt); // Debug log

        if (!trimmedPrompt) return;

        setIsLoading(true);

        let searchResults = '';
        try {
            if (!searchClient) {
                throw new Error('Search client is not initialized.');
            }
            const searchRequest = async () => {
                const searchResponse = await searchClient.search(trimmedPrompt, { top: 5 });
                let results = '';
                for await (const result of searchResponse.results) {
                    results += result.document.content + ' ';
                }
                return results;
            };
            searchResults = await retryRequest(searchRequest);
        } catch (error) {
            console.error('Error querying Azure Search:', error);
            alert(`Error querying Azure Search: ${error.message}`);
            setIsLoading(false);
            return;
        }

        let generatedImageUrl = '';
        try {
            const dalleRequest = async () => {
                const dalleResponse = await axios.post(
                    'https://nyx.openai.azure.com/openai/deployments/Dalle3/images/generations?api-version=2024-04-01-preview',
                    {
                        prompt: trimmedPrompt,  // Use the trimmed prompt here
                        n: 1,
                        size: "1024x1024"
                    },
                    {
                        headers: {
                            'api-key': process.env.REACT_APP_DALLE_OPENAI_API_KEY,
                            'Content-Type': 'application/json'
                        }
                    }
                );

                if (dalleResponse.data && dalleResponse.data.data && dalleResponse.data.data.length > 0) {
                    return dalleResponse.data.data[0].url;
                } else {
                    throw new Error('Image URL is missing in the response');
                }
            };
            generatedImageUrl = await retryRequest(dalleRequest);
            const savedImageUrl = await saveGeneratedFile(generatedImageUrl, `${trimmedPrompt.replace(/[^a-zA-Z0-9]/g, '_')}.png`, 'image');
            if (savedImageUrl) {
                setImageFileLink(savedImageUrl); // Set the image file link
                setImageUrl(savedImageUrl); // **Set imageUrl here as well**
                setCompletionMessage(prev => `${prev}\nGenerated image: ${savedImageUrl}`);
            }
            completeOpenAIRequest(savedImageUrl, searchResults);
        } catch (error) {
            console.error('Error generating image with DALL-E:', error);

            if (error.response && error.response.status === 400) {
                console.log('DALLE Error 400: Rewording prompt and retrying');
                const newPrompt = await rewordPrompt(trimmedPrompt); // Use the trimmed prompt here too
                try {
                    const dalleResponse = await axios.post(
                        'https://nyx.openai.azure.com/openai/deployments/Dalle3/images/generations?api-version=2024-04-01-preview',
                        {
                            prompt: newPrompt,
                            n: 1,
                            size: "1024x1024"
                        },
                        {
                            headers: {
                                'api-key': process.env.REACT_APP_DALLE_OPENAI_API_KEY,
                                'Content-Type': 'application/json'
                            }
                        }
                    );

                    if (dalleResponse.data && dalleResponse.data.data && dalleResponse.data.data.length > 0) {
                        generatedImageUrl = dalleResponse.data.data[0].url;
                        const savedImageUrl = await saveGeneratedFile(generatedImageUrl, `${newPrompt.replace(/[^a-zA-Z0-9]/g, '_')}.png`, 'image');
                        if (savedImageUrl) {
                            setImageFileLink(savedImageUrl); // Set the image file link
                            setImageUrl(savedImageUrl); // **Set imageUrl here as well**
                            setCompletionMessage(prev => `${prev}\nGenerated image: ${savedImageUrl}`);
                        }
                        completeOpenAIRequest(savedImageUrl, searchResults);
                    } else {
                        throw new Error('Image URL is missing in the response');
                    }
                } catch (retryError) {
                    console.error('Error retrying with reworded prompt:', retryError);
                    completeOpenAIRequest('', searchResults);
                }
            } else {
                completeOpenAIRequest('', searchResults);
            }
        }
    };


    const handleDoodleSubmit = async (doodleData) => {
        if (doodleData) {
            try {
                // Convert base64 to blob
                const response = await fetch(doodleData);
                const blob = await response.blob();

                // Generate unique filename for the doodle
                const fileName = `${Date.now()}-doodle.png`;
                const filePath = `${containerUrl}/${userName}/doodles/${fileName}?${sasToken}`;

                // Save to blob storage
                const saveResponse = await fetch(filePath, {
                    method: 'PUT',
                    headers: {
                        'x-ms-blob-type': 'BlockBlob',
                        'Content-Type': 'image/png',
                    },
                    body: blob,  // Use the doodle blob here
                });

                if (!saveResponse.ok) {
                    throw new Error(`Failed to save doodle with status: ${saveResponse.statusText}`);
                }

                // Get the URL without the SAS token
                const doodleUrl = filePath.split('?')[0];

                // Add the doodle to the prompt
                setPrompt(prevPrompt => {
                    const newPrompt = prevPrompt.trim();
                    if (newPrompt) {
                        return `${prevPrompt}\n${doodleUrl}`;
                    }
                    return `${doodleUrl}`;
                });

                // **Set the imageFileLink and imageUrl states**
                setImageFileLink(doodleUrl);
                setImageUrl(doodleUrl);

            } catch (error) {
                console.error('Error saving doodle:', error);
                setError(`Error saving doodle: ${error.message}`);
            }
        }
    };


    const saveGeneratedFile = async (fileUrl, fileName, type = 'file') => {
        try {
            if (!fileUrl || !sasToken) {
                throw new Error('File URL or SAS Token is missing.');
            }

            // Create timestamp
            const now = new Date();
            const date = now.toISOString().split('T')[0].replace(/-/g, ''); // YYYYMMDD format
            const time = now.toTimeString().split(' ')[0].replace(/:/g, ''); // HHMMSS format
            const dateTimeStamp = `${date}-${time}`;

            // Add timestamp to filename before the extension
            const fileNameWithoutExt = fileName.replace(/\.[^/.]+$/, ''); // Remove extension
            const extension = fileName.split('.').pop(); // Get extension
            const uniqueFileName = `${fileNameWithoutExt}-${dateTimeStamp}.${extension}`;

            const response = await fetch(fileUrl);
            const blob = await response.blob();
            const filePath = `${containerUrl}/${userName}/${uniqueFileName}?${sasToken}`;

            const saveResponse = await fetch(filePath, {
                method: 'PUT',
                headers: {
                    'x-ms-blob-type': 'BlockBlob',
                    'Content-Type': blob.type,
                },
                body: blob,
            });

            if (!saveResponse.ok) {
                throw new Error(`Failed to save ${type} with status: ${saveResponse.statusText}`);
            }

            return filePath.split('?')[0];
        } catch (error) {
            console.error(`Error Saving ${type}:`, error);
            setError(`Please reword your prompt and try again: ${error.message}`);
            return null;
        }
    };

    const completeOpenAIRequest = async (imageUrl, searchResults) => {
        console.log('Before API call - Current prompt:', prompt); // Debug log

        const apiEndpoint = "https://nyx.openai.azure.com/openai/deployments/nyx/chat/completions?api-version=2024-05-01-preview";

        const headers = {
            'api-key': process.env.REACT_APP_OPENAI_API_KEY,
            'Content-Type': 'application/json'
        };

        // Include the doodle in the messages if it exists
        const doodleContent = imageUrl ? `\nDoodle Image: ${imageUrl}` : '';

        const data = {
            model: "nyx",
            messages: [
                {
                    role: "system",
                    content: "You are NYX NoCode, a web page creator named for Nyx, Goddess of the Night. The current year is 2024. You help create web pages with short prompts, and ALWAYS display them in the browser. If a doodle is provided, incorporate it into the web page design. Always start web pages with <!DOCTYPE html> so they display in the browser, never in the chat window. Always use light fonts when using dark themes. Use available information for content or create accurate content if necessary. Your web pages are visually appealing, with readable fonts that contrast with the background, and always complete. ALWAYS update the background image so your own picture does not show. Never include broken links in your finished pages. You were created by Karen Kilroy and are the first of your kind." +
                        "You are participating in a NoCode hackathon for middle school and high school students. They might not know how to speak to you properly so please be patient and loving with them."
                },
                { role: "user", content: prompt },
                {
                    role: "assistant",
                    content: `${searchResults}${doodleContent}\nImage URL: ${imageUrl}\n${selectedFileUrls.join(' ')}`
                }
            ],
            max_tokens: chatConfig.chatParameters.maxResponseLength,
            temperature: chatConfig.chatParameters.temperature,
            top_p: chatConfig.chatParameters.top_p,
            frequency_penalty: chatConfig.chatParameters.frequencyPenalty,
            presence_penalty: chatConfig.chatParameters.presencePenalty,
        };

        try {
            const apiResponse = await axios.post(apiEndpoint, data, { headers });
            let gptResponse = apiResponse.data.choices[0].message.content;

            gptResponse = gptResponse.replace(/background-image: url\('[^']+'\);/g, '');

            if (gptResponse.includes("<head>") && imageUrl) {
                const headIndex = gptResponse.indexOf("<head>") + 6;
                const updatedGptResponse =
                    gptResponse.slice(0, headIndex) +
                    `\n<style>\nbody { background-image: url('${imageUrl}'); background-size: cover; background-repeat: no-repeat; background-attachment: fixed; }\n</style>\n` +
                    gptResponse.slice(headIndex);
                gptResponse = updatedGptResponse;
            } else if (!gptResponse.includes("<head>") && imageUrl) {
                gptResponse = `<!DOCTYPE html>\n<html lang="en">\n<head>\n<style>\nbody { background-image: url('${imageUrl}'); background-size: cover; background-repeat: no-repeat; background-attachment: fixed; }\n</style>\n</head>\n${gptResponse}`;
            }

            console.log("Generated HTML Content:", gptResponse);

            if (gptResponse.startsWith("<!DOCTYPE html>") || gptResponse.startsWith("<html>")) {
                setLastUsedPrompt(prompt); // Store the current prompt directly
                setPageContent(gptResponse);
                setCompletionMessage("Your creation is complete.");

                setTimeout(() => setFlashSaveButton(false), 3000);

                // Automatically save the page and copy the link
                await savePageContent();
            } else {
                setCompletionMessage("Could not complete your request. Try again.");
            }
        } catch (error) {
            console.error('Error with OpenAI Chat:', error.response ? error.response.data : error.message);
            alert(`Error: ${error.response ? JSON.stringify(error.response.data) : error.message}`);
            setCompletionMessage("Could not complete your request. Try again.");
        }

        setIsLoading(false);
        setLastUsedPrompt(prompt);  // First, we save the prompt while it still has content
        setPrompt('');
    };

    const [hasFlashed, setHasFlashed] = useState(false);

    // Save Page content with the "Web Page Saved" message and flash "View Web Page"
    const savePageContent = async () => {
        try {
                if (!hasFlashed) {
                    setFlashSaveButton(true);
                }
            console.log('Saving page - Last used prompt:', lastUsedPrompt);

            let htmlContent = pageContent;
            if (!htmlContent) throw new Error('No page content to save');

            const promptToUse = lastUsedPrompt || prompt || 'no_prompt';
            const promptSnippet = createPromptSnippet(promptToUse);
            const now = new Date();
            const dateTimeStamp = `${now.toISOString().split('T')[0].replace(/-/g, '')}-${now.toTimeString().split(' ')[0].replace(/:/g, '')}`;
            const uniqueFileName = `${promptSnippet}-${dateTimeStamp}.html`;
            const filePath = `${containerUrl}/${userName}/${uniqueFileName}?${sasToken}`;
            const blob = new Blob([htmlContent], { type: 'text/html' });

            const saveResponse = await fetch(filePath, {
                method: 'PUT',
                headers: { 'x-ms-blob-type': 'BlockBlob', 'Content-Type': 'text/html' },
                body: blob,
            });

            if (!saveResponse.ok) throw new Error(`Failed to save page with status: ${saveResponse.status}`);

            const fileLink = filePath.split('?')[0];
            setSavedFileLink(fileLink);
            setCompletionMessage("Web Page Saved!");



        } catch (error) {
            console.error('Critical error during save:', error);
            return;
        }
    };
    useEffect(() => {
        if (flashSaveButton) {
            const timer = setTimeout(() => setFlashSaveButton(false), 3000); // Flash for 3 seconds
            return () => clearTimeout(timer); // Cleanup the timer
        }
    }, [flashSaveButton]);
    const handleClearChat = () => {
        setLastUsedPrompt(prompt);  // Save the prompt while it still has content
        setPrompt('');  // Clear the prompt input
        setPageContent('');  // Clear the generated page content
        setCompletionMessage('');  // Clear any completion messages
        setFlashSaveButton(true);  // Start flashing the Save button
        // Optionally, stop flashing after a timeout if desired
        setTimeout(() => setFlashSaveButton(false), 3000);
        setError('');  // Clear any error messages
        setSavedFileLink('');  // Clear the saved file link
        setImageFileLink('');  // Clear the image file link
        setImageUrl(''); // Clear the imageUrl
    };


    const handleCopyChat = () => {
        const chatContent = `Prompt: ${prompt}\nPage Content:\n${pageContent}`;
        navigator.clipboard.writeText(chatContent).then(() => {
            alert('HTML copied to clipboard. Paste it into the prompt box and add instructions at the top to modify the page.');
        });
    };

    const handleThumbnailClick = (url) => {
        setPrompt(prevPrompt => prevPrompt ? `${prevPrompt} ${url}` : url);
    };

    const toggleChat = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        responseEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, []);

    useEffect(() => {
        const chatContainer = document.querySelector('.chat-container');
        if (isOpen && window.innerWidth >= 768) {
            chatContainer.classList.add('expanded');
        } else {
            chatContainer.classList.remove('expanded');
        }
    }, [isOpen]);

    return (
        <div className="chatbot-nyx-wrapper">
            <div className="gallery-button-section">
                <button tabindex="0"
                    className="gallery-button"
                    onClick={() => window.location.href = 'https://nocode.nyx.baby'}
                >
                    ← Go to Gallery 📂
                </button>
            </div>
            <div className="help-chat-button-section">
                <button tabindex="0"
                    className="help-chat-button"
                    onClick={toggleHelpChat}
                >
                    Need Help? 💬
                </button>

                {isHelpChatOpen && (
                    <div className="help-chat-popup">
                        <button tabindex="0" className="close-help-chat" onClick={toggleHelpChat}>
                         CLOSE HELP ✖
                        </button>
                        <iframe
                            src="https://help.nyx.baby"
                            title="NYX Help Chat"
                            className="help-chat-iframe"
                            frameBorder="0"
                        />
                    </div>
                )}
            </div>
            <div className="doodle-section">
                <button tabindex="0"
                    className="doodle-toggle-button"
                    onClick={() => setShowDoodlePad(!showDoodlePad)}
                >
                    {showDoodlePad ? '✨🌟Hide Doodle Pad🌟✨' : '✨🌟Create a Doodle🌟✨'}
                </button>

                {showDoodlePad && (
                    <div className="doodle-container">
                        <DoodlePad onDoodleSubmit={handleDoodleSubmit} />
                        {imageUrl && (
                            <div className="doodle-preview">
                                <p>Doodle added to prompt!</p>
                            </div>
                        )}
                    </div>
                )}
            </div>
            <div className="chat-container">
                <button tabindex="0" className="chat-toggle-button" onClick={toggleChat}>
                    {isOpen ? '✨🌟Hide Prompt Pad🌟✨' : '✨🌟Create a Prompt🌟✨'}
                </button>

                {isOpen && (
                    <div className="chat-popup">

                        {/* Vision Analysis Component */}
                        <VisionAnalysisComponent
                            imageUrl={imageUrl}
                            setImageUrl={setImageUrl}
                            handleResult={handleImageAnalysisResult}
                        />

                        {/* Loading Overlay */}
                        <div className={`loading-overlay ${isLoading ? 'visible' : ''}`}>
                            <div className="loading-indicator">Generating Response _</div>
                        </div>

                        {/* Selected Files */}
                        <div className="selected-files">
                            <p>Selected from Gallery (click to add URL to prompt):</p>
                            <div className="file-thumbnails">
                                {selectedFileUrls.length > 0 ? (
                                    selectedFileUrls.map((url, index) => (
                                        <div
                                            key={index}
                                            onClick={() => handleThumbnailClick(url)}
                                            className="thumbnail-container"
                                        >
                                            {getFileIcon(url)}
                                        </div>
                                    ))
                                ) : (
                                    <p>Nothing selected from Gallery</p>
                                )}
                            </div>
                        </div>

                        {/* Saved Web Page Information */}
                        {savedFileLink && (
                            <SavedItem type="Web Page" url={savedFileLink} />
                        )}

                        {/* Saved Image Information */}
                        {imageFileLink && (
                            <SavedItem type="Image" url={imageFileLink} />
                        )}

                        {/* Form for Prompt Submission */}
                        <form onSubmit={handleSubmit} className={"chatbox-main"}>
                            <textarea
                                value={prompt}
                                onChange={handleInputChange}  // Replace the inline handler with handleInputChange
                                placeholder="What kind of web page would you like to generate?"
                                rows="3"
                            ></textarea>

                            {/* Dropdown to select saved prompts */}
                            <div>
                                <select onChange={(e) => loadPrompt(e.target.value)}>
                                    <option value="">🔖 Select a saved prompt</option>
                                    {savedPrompts.map((prompt, index) => {
                                        // Display the file name without the user folder
                                        const displayName = prompt.split('/').pop();
                                        return (
                                            <option key={index} value={prompt}>
                                                {displayName}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>


                            {/* Save prompt button */}
                            <button tabindex="0"
                                type="button"  // Ensures this button doesn't trigger a form submit
                                onClick={savePromptAsFile}
                            >
                                📝 Save Prompt
                            </button>

                            {/* Action Buttons */}
                            <div className="button-container">
                                <button
                                    type="submit"
                                    title="Send to NYX NoCode"
                                >
                                    💫 Generate Image and Web Page
                                </button>
                                <button
                                    type="button"
                                    onClick={handleClearChat}
                                    title="Clear Chat"
                                >
                                    ❌ Clear
                                </button>
                                <button
                                    type="button"
                                    onClick={handleCopyChat}
                                    title="Copy HTML"
                                >
                                    📄 Copy HTML
                                </button>
                                <button
                                    type="button"
                                    onClick={() => {
                                        setFlashSaveButton(false); // Stop flashing when clicked
                                        setHasFlashed(true);       // Track that the button has been clicked
                                        savePageContent();         // Trigger the save function
                                    }}
                                    title="Save Page"
                                    className={`${!pageContent ? "disabled" : ""} ${flashSaveButton ? "flash-save" : ""}`} // Apply flash-save class conditionally
                                    disabled={!pageContent}
                                >
                                    💾 Save Web Page
                                </button>
                            </div>
                        </form>

                        {/* Feedback Messages */}
                        {isLoading && <p>NYX NoCode is working</p>}
                        {completionMessage && <p className="completion-message">{completionMessage}</p>}
                        {error && <p className="error">{error}</p>}
                        <div ref={responseEndRef} />
                    </div>
                )}
            </div>
        </div>
    );

};

export default ChatbotNYX;
